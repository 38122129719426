@import '../../../assets/styles/colors.scss';
@import '../../../assets/styles/fontMixins.scss';

$tabMargin: 40px;

.container {
  border-bottom: 1px solid $light;
  margin-bottom: 30px;

  .tabItemContainer {
    position: relative;

    .tabItem {
      font-size: 0.875em;
      color: $baltic-sea;
      padding: 0 0 9px 0;
      margin-right: $tabMargin;

      &[aria-selected='true'] ~ .tabBorder {
        position: absolute;
        width: calc(100% - #{$tabMargin});
        height: 10px;
        background-color: $light-indigo;
        border-radius: 0 0px 10px 10px;

        &.tabBorderPrimary {
          background-color: $aqua-blue;
        }
      }
    }
  }
}

.tabContentContainer {
  .tabContent {
    width: 100%;
  }
}

.active {
  &[aria-selected='false'] {
    opacity: 0.3;
    @include proxima-nova-regular;
  }
}
