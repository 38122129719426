@import '../../../assets/styles/colors.scss';

.container {
  background-color: $white;
  padding: 6px;
  border-radius: 4px;
  width: fit-content;

  &.isBlack {
    background-color: $tuna;
  }
  &.isBlue {
    background-color: $sky-blue;
  }
  &.isBlueGreen {
    background-color: $blue-green;
  }
  &.isGreen {
    background-color: $irish-green;
  }
  &.isOrange {
    background-color: $blaze-orange;
  }
  &.isRose {
    background-color: $purple-pink;
  }
  &.isPurple {
    background-color: $light-indigo;
  }
  &.isRed {
    background-color: $crimson;
  }
  &.isYellowGreen {
    background-color: $rio-grande;
  }

  &.hasRoundBorders {
    border-radius: 100px;
  }
}
