@import '../../../assets/styles/colors.scss';
@import '../../../assets/styles/fontMixins.scss';

.infoBadge {
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $bright-turquoise;
  border-radius: 7px;
  padding: 20px 50px;
  color: $white;
  max-width: 334px;
  width: 100%;
  margin: 0 auto;

  .infoIcon {
    font-size: 2em;
  }
  .infoTitle, .infoText {
    margin-top: 10px;
    margin-bottom: 0;
    @include proxima-nova-semibold;
  }
  .infoText {
    font-size: 0.75em;
    text-align: center;
    @include proxima-nova-thin;
  }
  &.isOrange {
    background-color: $bright-orange;
  }
}
