@import '../../../../../assets/styles/colors.scss';
@import '../../../../../assets/styles/fontMixins.scss';
@import '../../../../../assets/styles/mediaQueriesMixings.scss';

.container {
  display: flex;
  flex-direction: column;
  background-color: $aqua-haze;
  align-items: center;
  padding: 20px 20px 60px;

  .backLinkContainer {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    align-self: flex-start;
    gap: 5px;
    cursor: pointer;

    &:hover {  
      .arrow {
        transform: translateX(-4px);
      }
    }

    .arrow {
      width: 17px;
    }

    .backLink {
      font-size: 0.9375em;
      color: $light-indigo;
      transition: 0.2s ease-in-out;
      transform: translateX(0);
    }
  }

  .title {
    margin-top: 60px;
    margin-bottom: 0;
    text-align: center;
    font-size: 2em;
    color: $purple-iris;
    @include proxima-nova-bold;
  }

  .subtitle {
    margin-top: 20px;
    margin-bottom: 0;
    text-align: center;
    font-size: 1.125em;
    color: $baltic-sea;
    @include proxima-nova-semibold;
  }

  .fch {
    margin-top: 20px;
    margin-bottom: 0;
    font-size: 1.5em;
    display: flex;
    flex-direction: column;
    align-items: center;
    word-break: break-all;

    .fchTitle {
      @include proxima-nova-thin;
    }

    .fchValue {
      text-align: center;
      @include proxima-nova-bold;
    }
  }
}

.tabContainer {
  margin-top: 30px;
  height: 44px;
  background-color: $baltic-sea;
  border-radius: 27px;

  .tabItemContainer {
    height: 44px;

    .tabItem {
      border-radius: 27px;
      color: $white;
      height: inherit;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.875em;
      cursor: pointer;
      @include proxima-nova-semibold;
      opacity: 1;

      &[aria-selected='true'] {
        background-color: $light-indigo;
        transition: background-position 0.3s ease;
      }
    }
  }
}

.tabContentContainer {
  width: 100%;
}

@include lg {
  .container {
    padding: 50px;

    .backLinkContainer {
      margin-left: 30px;
    }

    .title {
      margin-top: 100px;
      font-size: 3.25em;
    }

    .subtitle {
      font-size: 1.5em;
    }

    .fch {
      margin-top: 50px;
      font-size: 2em;
      flex-flow: row wrap;
      justify-content: center;
      gap: 15px;
    }
  }
}
