@import '../../../../../../../assets/styles/colors.scss';
@import '../../../../../../../assets/styles/mediaQueriesMixings.scss';

.nftItem {
  width: 100%;
  max-width: 335px;
  padding: 15px;
  border-radius: 8px;
  background-color: $alabaster;
  height: 305px;
  cursor: pointer;

  .imageContainer {
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 305px;
    height: 100%;
    overflow: hidden;

    &.notImage {
      background-color: $dark-jungle-green;
    }

    .image {
      width: 100%;
      border-radius: 8px;

      &.notImage {
        width: 68px;
      }

      &.hasMultipleSignatures {
        width: 200px;
      }
    }
  }

  &:hover {
    opacity: 0.7;
  }
}

@include lg {
  .nftItem {
    max-width: 335px;
    width: 335px;

    &.hasSmallIconSize {
      max-width: 235px;
      width: 235px;
      height: 235px;

      .imageContainer {
        max-height: 235px;
      }
    }
  }
}

